import React from 'react';
import ReactDOM from 'react-dom';

import DomainFeatureFlags from './components/DomainFeatureFlags';
import './domain-feature-flags.scss';

ReactDOM.render(
  <DomainFeatureFlags />,
  document.querySelector('#domain-feature-flags')
);
