import React from 'react';

import { domainFeatureFlags } from 'common/frontend/internal';
import { getAllGates } from 'common/core/config_gates/provisions';
import CachedFilterBar from 'common/components/CachedFilterBar/CachedFilterBar';

import FlagContent from './FlagContent';

class DomainFeatureFlags extends React.Component {
  state = {
    flagsWithGates: [],
    flagsWithGatesCached: [],
  };

  async componentDidMount() {
    const allGates = await getAllGates();
    const gates = allGates.filter((gate) => gate.provisionLookup.startsWith('flag:'));

    const flags = await domainFeatureFlags(this.getTargetDomain());

    const flagsWithGates = applyGatesToFlags(gates, flags);
    this.setState({ flagsWithGates, flagsWithGatesCached: flagsWithGates });
  }

  // not always the same as getCurrentDomain, so is loaded in view internal/feature_flags.html.erb
  getTargetDomain = () => window.socrata.targetCname;

  render() {
    const {
      flagsWithGates,
      flagsWithGatesCached,
    } = this.state;

    const domain = this.getTargetDomain();

    const filterProps = {
      autoFocus: true,
      className: 'feature-flag-filter',
      dataCached: flagsWithGatesCached,
      searchKeys: ['name',  'description'], // used by the query to match on values of `dataCached` objects
      handleQueryMatches: (matches) => this.setState({ flagsWithGates: matches }),
      onClearSearch: () => this.setState({ flagsWithGates: flagsWithGatesCached })
    };

    const flagElements = flagsWithGates && flagsWithGates.map((flag) =>
      <FlagContent
        {...flag}
        domain={domain}
        key={flag.name} />
    );

    return (
      <div>
        <CachedFilterBar {...filterProps} />
        {flagElements}
      </div>
    );
  }
}

const applyGatesToFlags = (gates, flags) => {
  return Object.keys(flags)
    .sort()
    .map((flagName) => {
      const matchingGate = gates.find((gate) => gate.provisionLookup.includes(flagName));
      const gateProperties = matchingGate && { hasGate: true, gateDetails: matchingGate };

      return {
        name: flagName,
        ...flags[flagName],
        ...gateProperties  // if they exist
      };
    });
};

export default DomainFeatureFlags;
