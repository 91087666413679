import React from 'react';
import get from 'lodash/get';

import GatedIcon from 'common/components/GatedIcon';
import { showErrorToastNow, showSuccessToastNow } from 'common/components/ToastNotification/Toastmaster';
import { resetDomainFeatureFlags, setDomainFeatureFlags } from 'common/frontend/internal';
import { getCurrentUser } from 'common/current_user';
import GateableButton from 'common/components/GateableButton';

import FlagInputs from './FlagInputs';

class FlagContent extends React.Component {
  state = { value: this.props.value };

  updateValue = (value) => this.setState({ value });

  parseDescriptionLinkHelpers = (descriptionText) => {
    const recursiveParse = (toBeParsed, parsed = []) => {
      const match = toBeParsed.match(/(jira:)?EN-\d+/);

      if (!match) {
        return [...parsed, toBeParsed];
      }

      const matchedStr = match[0];
      const jiraLink = jiraLinkFor(matchedStr);
      const [before, after] = toBeParsed.split(matchedStr);

      return recursiveParse(after, [...parsed, before, jiraLink]);
    };

    const jiraLinkFor = (matchedStr) => {
      const jiraTicketId = matchedStr.replace('jira:', '');
      const jiraTicketUrl = `https://socrata.atlassian.net/browse/${jiraTicketId}`;
      return <a key={jiraTicketId} href={jiraTicketUrl} target="_blank">{jiraTicketId}</a>;
    };

    const parsedContent = recursiveParse(descriptionText);

    return <p className="description">{parsedContent}</p>;
  };

  toggleOnClick = () => {
    const value = !this.state.value;
    this.setState({ value }, this.updateOnClick);
  };

  updateOnClick = () => {
    const { name, domain } = this.props;
    const payload = { [name]: this.state.value };

    return setDomainFeatureFlags(domain, payload)
      .then(showResponseToasts)
      .catch((err) => console.error('setDomainFeatureFlags error:', err));
  };

  resetOnClick = () => {
    const { name, domain, defaultValue } = this.props;
    const payload = { [name]: defaultValue };

    return resetDomainFeatureFlags(domain, payload)
      .then(showResponseToasts)
      .then(this.updateValue(defaultValue))
      .catch((err) => console.error('resetDomainFeatureFlags error:', err));
  };

  render() {
    const {
      type,
      name,
      description,
      defaultValue,
      hasGate,
      gateDetails,
    } = this.props;

    const { value } = this.state;

    const allowedUsers = get(gateDetails, 'allowedUsers', []);
    const editingDisabled = allowedUsers.length > 0 &&
      !allowedUsers.some((user) => getCurrentUser().id === user.id);

    const booleanToggleButton = type.includes('boolean') &&
      <GateableButton gate={gateDetails} onClick={this.toggleOnClick}>
        Toggle to {String(!value)}
      </GateableButton>;

    const flagInputsProps = {
      value,
      type,
      name,
      disabled: editingDisabled,
      updateValue: this.updateValue
    };

    return (
      <div className="flag-content">
        <h5 className="title">{name}</h5>
        {this.parseDescriptionLinkHelpers(description)}
        <div className="values">
          <div className="default-value">Default Value: {defaultValue.toString()}</div>
          <div className="action-row">
            <FlagInputs {...flagInputsProps} />
            <div className="action-buttons">
              {hasGate && <GatedIcon gate={gateDetails} />}
              {booleanToggleButton}
              <GateableButton gate={gateDetails} onClick={this.updateOnClick}>
                Update
              </GateableButton>
              <GateableButton gate={gateDetails} onClick={this.resetOnClick}>
                Reset To Default
              </GateableButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// response: JSON response from setting/resetting feature flags
const showResponseToasts = (response) => {
  response.infos.forEach((info) => showSuccessToastNow(info));
  response.errors.forEach((error) => showErrorToastNow(error));
};

export default FlagContent;
