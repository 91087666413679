import React from 'react';

import { Dropdown } from 'common/components/Dropdown';

const baseClassName = 'flag-input';

// props.type: array of possible types, see FFM README for more info
// props.value: current value of flag, if set
// props.updateValue: callback to change value of flag in state
// props.disabled: if flag is gated and user is not on allowed list
const FlagInputs = (props) => {
  const { type } = props;

  const renderBooleanInputs = type.includes('boolean');
  const renderTextInput = type.some((t) => ['string', 'integer'].includes(t));
  const dropdownOptions = type.flat()
    .filter((t) => !['string', 'boolean', 'integer'].includes(t))
    .map((t) => ({
        title: t,
        value: t
      })
    );

  const renderDropdownOptions = dropdownOptions.length > 0;

  return (
    <div className="flag-inputs">
      {renderBooleanInputs && <BooleanInputs {...props} />}
      {renderTextInput && <TextInput {...props} />}
      {renderDropdownOptions && <DropdownInput {...props} options={dropdownOptions} />}
    </div>
  );
};

const BooleanInputs = ({ value, updateValue, disabled }) => (
  <span className={`${baseClassName} flag-input-boolean`}>
    <label>
      <input type="radio"
             value="true"
             disabled={disabled}
             checked={value === true}
             onChange={() => updateValue(true)} /> true
    </label>
    <label>
      <input type="radio"
             value="false"
             disabled={disabled}
             checked={value === false}
             onChange={() => updateValue(false)}/> false
    </label>
  </span>
);

const TextInput = ({ value, updateValue, disabled }) => {
  const onChange = (event) => updateValue(event.target.value);

  return (
    <span className={`${baseClassName} flag-input-text`}>
      <label>Current Value:</label>
      <input type="text" value={value} disabled={disabled} onChange={onChange} />
    </span>
  );
};

const DropdownInput = ({ value, updateValue, disabled, options }) => {
  const dropdownProps = {
    options,
    value,
    disabled: disabled,
    onSelection: ({ value: newVal }) => updateValue(newVal)
  };

  return (
    <span className={`${baseClassName} flag-input-dropdown`}>
      <label>Current value:</label>
      <Dropdown {...dropdownProps} />
    </span>
  );
};

export default FlagInputs;
